<template>
  <b-card>
    <form-component :technician="technician" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/technicians/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/technicians';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      technician: {
        id: '',
        county_id: '',
        name: '',
        surname: '',
        phone: '',
        email: '',
        postal_code: '',
        city: '',
      },
    };
  },

  methods: {
    async onSubmit(form) {
      try {
        await create(form);
        this.showSuccessNotification('Dane zapisane', 'Serwisant został dodany.');
        this.$router.push({ name: 'technicians-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania serwisanta. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
